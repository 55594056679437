// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-basics-benzohistory-js": () => import("./../../../src/pages/basics/benzohistory.js" /* webpackChunkName: "component---src-pages-basics-benzohistory-js" */),
  "component---src-pages-basics-benzolist-js": () => import("./../../../src/pages/basics/benzolist.js" /* webpackChunkName: "component---src-pages-basics-benzolist-js" */),
  "component---src-pages-basics-benzolisteng-js": () => import("./../../../src/pages/basics/benzolisteng.js" /* webpackChunkName: "component---src-pages-basics-benzolisteng-js" */),
  "component---src-pages-basics-benzowords-js": () => import("./../../../src/pages/basics/benzowords.js" /* webpackChunkName: "component---src-pages-basics-benzowords-js" */),
  "component---src-pages-basics-index-js": () => import("./../../../src/pages/basics/index.js" /* webpackChunkName: "component---src-pages-basics-index-js" */),
  "component---src-pages-basics-withdrawalsymptoms-js": () => import("./../../../src/pages/basics/withdrawalsymptoms.js" /* webpackChunkName: "component---src-pages-basics-withdrawalsymptoms-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-contacted-js": () => import("./../../../src/pages/contacted.js" /* webpackChunkName: "component---src-pages-contacted-js" */),
  "component---src-pages-doctors-js": () => import("./../../../src/pages/doctors.js" /* webpackChunkName: "component---src-pages-doctors-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-indexeng-js": () => import("./../../../src/pages/indexeng.js" /* webpackChunkName: "component---src-pages-indexeng-js" */),
  "component---src-pages-introduction-js": () => import("./../../../src/pages/introduction.js" /* webpackChunkName: "component---src-pages-introduction-js" */),
  "component---src-pages-introductioneng-js": () => import("./../../../src/pages/introductioneng.js" /* webpackChunkName: "component---src-pages-introductioneng-js" */),
  "component---src-pages-medias-js": () => import("./../../../src/pages/medias.js" /* webpackChunkName: "component---src-pages-medias-js" */),
  "component---src-pages-patients-js": () => import("./../../../src/pages/patients.js" /* webpackChunkName: "component---src-pages-patients-js" */),
  "component---src-pages-privacypolicy-js": () => import("./../../../src/pages/privacypolicy.js" /* webpackChunkName: "component---src-pages-privacypolicy-js" */),
  "component---src-pages-profile-js": () => import("./../../../src/pages/profile.js" /* webpackChunkName: "component---src-pages-profile-js" */),
  "component---src-pages-salesad-js": () => import("./../../../src/pages/salesad.js" /* webpackChunkName: "component---src-pages-salesad-js" */),
  "component---src-templates-article-js": () => import("./../../../src/templates/article.js" /* webpackChunkName: "component---src-templates-article-js" */)
}

